import axios from 'axios';
import { auth } from './firebase';
class LicenceService {
  createToken = async () => {
    const user = auth.currentUser;
    if (user) {
      console.log('');
    } else {
      window.location.replace('/');
    }
    const token = user && (await user.getIdToken());
    const payloadHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`
      }
    };
    return payloadHeader;
  };

  //eslint-disable-next-line
  async getLicence(): Promise<any> {
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    const header = await this.createToken();
    try {
      const res = await request.get(
        `/api/1/licenses/search?pageSize=30&pageNumber=1`,
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }
  //eslint-disable-next-line
  async handleFilter(value: any): Promise<any> {
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    const header = await this.createToken();
    try {
      const res = await request.get(
        `/api/1/licenses/search?status=${value}&pageSize=30&pageNumber=1`,
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }

  //eslint-disable-next-line
  async handleTestFilter(): Promise<any> {
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    const header = await this.createToken();
    try {
      const res = await request.get(
        `/api/1/licenses/search?pageSize=30&pageNumber=1&testLicense=true`,
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  //eslint-disable-next-line
  async postNewLicence(
    number: any,
    numberOfTerminals: any,
    testLicence: boolean
  ): Promise<any> {
    const header = await this.createToken();
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    try {
      const res = await request.post(
        `/api/1/licenses/generate`,
        {
          numberOfLicenses: number,
          numberOfTerminals: numberOfTerminals,
          testLicense: testLicence
        },
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  //eslint-disable-next-line
  async postActiveLicence(licenceKey: any, machineUUID: any, email: any) {
    const header = await this.createToken();
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    try {
      const res = await request.post(
        `/api/1/licenses/activate`,
        {
          licenseKey: licenceKey,
          machineUUID: machineUUID,
          email: email
        },
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }
  //eslint-disable-next-line
  async postRevokeLicence(key: any): Promise<any> {
    const header = await this.createToken();
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    try {
      const res = await request.post(
        `/api/1/licenses/revoke`,
        {
          licenseKey: key
        },
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  //eslint-disable-next-line
  async updateTerminal(licenceKey: any, numberOfTerminals: any) {
    const header = await this.createToken();
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });
    try {
      const res = await request.post(
        `/api/1/licenses/update`,
        {
          licenseKey: licenceKey,
          numberOfTerminals: numberOfTerminals
        },
        header
      );
      const licence = res.data;
      return licence;
    } catch (e) {
      console.log('');
    }
  }
}
export const licences = new LicenceService();
