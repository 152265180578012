import React from 'react';
import Header from '../header/Header';
import Navbar from '../navbar/Navbar';
import Table from '../tableData/TableData';
import './Dashboard.scss';

function Dashboard() {
  return (
    <div className="dashboard">
      <Header />
      <div className="row">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="flex-column">
          <Table />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
