import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './Navbar.scss';
import homeIcon from 'src/assets/home-32px.png';
import infoIcon from 'src/assets/info-32px.png';
import settingsIcon from 'src/assets/settings.svg';
import separatorIcon from 'src/assets/separator.svg';

const Navbar = () => {
  return (
    <>
      <div className="menu__icons">
        <a className="menu__icons__a">
          <img src={homeIcon} alt="home" />
        </a>
        <a className="menu__icons__a">
          <img src={infoIcon} alt="info" />
        </a>
        <a className="menu__icons__a menu__icons__a__settings">
          <img src={separatorIcon} alt="separator" />
          <img className="settings_icon" src={settingsIcon} alt="settings" />
        </a>
      </div>
      <Menu>
        <a className="menu__item">Home</a>
        <a className="menu__item">Info</a>
        <a className="menu__item">Settings</a>
      </Menu>
    </>
  );
};

export default Navbar;
