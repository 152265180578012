import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreHoriz';
import { useState } from 'react';
import CloseIcon from 'src/assets/close.png';
import InputMask from 'react-input-mask';
import Alert from '@mui/material/Alert';
import './MoreOptions.scss';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

const options = ['Activate', 'Revoke', 'Edit', 'Info'];
const ITEM_HEIGHT = 48;
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function LongMenu(props: any) {
  // const [licenceKey, setLicenceKey] = useState(props.cell.licenseKey);
  const [machineUUID, setMachineUUID] = useState('');
  const [numberOfTerminals, setNumberOfTerminals] = useState(
    props.cell.numberOfTerminals
  );
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // const handleMachineUUID = (event: any) => setMachineUUID(event.target.value);
  const [email, setEmail] = useState('');
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [key, setKey] = useState('');
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState('');
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // const handleKey = (event: any) => setKey(event.target.value);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };
  const licenceKey = props.cell.licenseKey;

  const status = props.cell.status;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setModal(!modal);
  };

  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const [isDisabled, setDisibility] = useState(true);

  const handleEmail = (e: any) => {
    setDisibility(!regex.test(e.target.value));
    setEmail(e.target.value);
  };

  const handleMachineUUID = (e: any) => {
    setMachineUUID(e.target.value);
  };

  const handleNumberOfTerminals = (event: any) => {
    const min = 0;
    const max = 99;
    const numberOfTerminals = Math.max(
      min,
      Math.min(max, Number(event.target.value))
    );
    setNumberOfTerminals(numberOfTerminals);
  };

  const handleKey = (e: any) => {
    if (e.target.value.length >= e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
      setDisibility(false);
      setKey(e.target.value);
      e.preventDefault();
      return;
    }
    setDisibility(true);
  };

  const toggleModal = (modalOption: string) => {
    setModal(true);
    setModalType(modalOption);
  };

  const [openSnack, setOpenSnack] = useState(false);

  const handleClickSnack = () => {
    setOpenSnack(!openSnack);
  };

  const handleCloseSnack = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const renderModal = () => {
    if (modalType === 'Activate') {
      if (status === 'REVOKE' || status === 'ACTIVE') {
        return (
          <div>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Snackbar
                open={openSnack}
                autoHideDuration={2000}
                onClose={() => {
                  handleCloseSnack();
                  closeModal();
                  setOpenSnack(false);
                }}>
                <Alert
                  icon={false}
                  onClose={() => {
                    handleCloseSnack();
                    closeModal();
                    setOpenSnack(false);
                  }}
                  variant="filled"
                  severity="error"
                  sx={{ width: '100%' }}>
                  Licence cannot be activated!
                </Alert>
              </Snackbar>
            </Stack>
          </div>
        );
      } else if (props.cell.status === 'NEW')
        return (
          <div className="modal">
            <div className="overlay">
              <div className="modal-content">
                <>
                  <h2>Enter Machine Id and email</h2>
                  <InputMask
                    mask="********-****-****-****-************"
                    onChange={handleMachineUUID}
                    pattern="([a-z0-9]){8}-([a-z0-9]){4}-([a-z0-9]){4}-([a-z0-9]){4}-([a-z0-9]){12}"
                    placeholder="enter machine id"
                    size={32}
                  />
                  <input placeholder="enter email" onChange={handleEmail} />
                  <button
                    className="button_new"
                    onClick={(e) => {
                      props.activateLicence(licenceKey, machineUUID, email);
                      handleClose();
                      closeModal();
                      setOpenSnack(false);
                      e.preventDefault();
                    }}
                    disabled={isDisabled || !machineUUID || !email}>
                    Submit
                  </button>
                  <button
                    onClick={() => {
                      handleClose();
                      closeModal();
                      setOpenSnack(false);
                    }}
                    className="close-modal">
                    <img src={CloseIcon} />
                  </button>
                </>
              </div>
            </div>
          </div>
        );
    } else if (modalType === 'Revoke') {
      if (status === 'REVOKE' || status === 'NEW') {
        return (
          <div>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Snackbar
                open={openSnack}
                autoHideDuration={2000}
                onClose={() => {
                  handleCloseSnack();
                  closeModal();
                  setOpenSnack(false);
                }}>
                <Alert
                  icon={false}
                  onClose={() => {
                    handleCloseSnack();
                    closeModal();
                    setOpenSnack(false);
                  }}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}>
                  Licence cannot be revoked!
                </Alert>
              </Snackbar>
            </Stack>
          </div>
        );
      } else if (props.cell.status === 'ACTIVE')
        return (
          <div className="modal">
            <div className="overlay">
              <div className="modal-content">
                <h2>Enter Licence Key</h2>
                <input
                  placeholder="enter licence key"
                  onChange={handleKey}
                  required
                  maxLength={36}
                />
                <button
                  className="button_new"
                  onClick={(e) => {
                    props.revokeLicence(key);
                    handleClose();
                    closeModal();
                    setOpenSnack(false);
                    e.preventDefault();
                  }}
                  disabled={isDisabled || !key}>
                  Submit
                </button>
                <button
                  onClick={() => {
                    handleClose();
                    closeModal();
                    setOpenSnack(false);
                  }}
                  className="close-modal">
                  <img src={CloseIcon} />
                </button>
              </div>
            </div>
          </div>
        );
    } else if (modalType === 'Info') {
      return (
        <div>
          <div className="modal">
            <div className="overlay">
              <div className="modal-content modal-content-info">
                <h2 className="licence_info_title">Licence Info</h2>
                <ul className="licence_info">
                  <li>
                    <span>Licence key: </span>
                    {props.cell.licenseKey}
                  </li>
                  <li>
                    <span>Email: </span>
                    {props.cell.email}
                  </li>
                  <li>
                    <span>Machine ID: </span>
                    {props.cell.machineUUID}
                  </li>
                  <li>
                    <span>Terminals: </span>
                    {props.cell.numberOfTerminals}
                  </li>
                  <li>
                    <span>Created at: </span>
                    {props.cell.createdAt
                      ? moment(props.cell.createdAt).format('DD-MM-YYYY')
                      : ''}
                  </li>
                  <li>
                    <span>Activated at: </span>
                    {props.cell.activatedAt
                      ? moment(props.cell.activatedAt).format('DD-MM-YYYY')
                      : ''}
                  </li>
                  <li>
                    <span>Revoked at: </span>
                    {props.cell.revokedAt
                      ? moment(props.cell.revokedAt).format('DD-MM-YYYY')
                      : ''}
                  </li>
                  <li>
                    <span>Expires at: </span>
                    {props.cell.expiresAt
                      ? moment(props.cell.expiresAt).format('DD-MM-YYYY')
                      : ''}
                  </li>
                  <li>
                    <span>Status: </span>
                    {status}
                  </li>
                </ul>
                <button
                  onClick={() => {
                    handleClose();
                    closeModal();
                    setOpenSnack(false);
                  }}
                  className="close-modal">
                  <img src={CloseIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div className="modal">
          <div className="overlay">
            <div className="modal-content">
              <h2>Enter number of terminals</h2>
              <input
                placeholder="enter number of terminals"
                value={numberOfTerminals}
                required
                min="1"
                max="99"
                onChange={handleNumberOfTerminals}
              />
              <button
                className="button_new"
                onClick={(e) => {
                  props.updateNumberOfTerminal(licenceKey, numberOfTerminals);
                  handleClose();
                  closeModal();
                  setOpenSnack(false);
                  e.preventDefault();
                }}
                disabled={!numberOfTerminals}>
                Submit
              </button>
              <button
                onClick={() => {
                  handleClose();
                  closeModal();
                  setOpenSnack(false);
                }}
                className="close-modal">
                <img src={CloseIcon} />
              </button>
            </div>
          </div>
        </div>
      );
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={function () {
          handleClose();
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}>
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              toggleModal(option);
              handleClickSnack();
              handleClose();
            }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      <>{modal && renderModal()}</>
    </div>
  );
}
