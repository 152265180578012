import React, { useEffect, useState } from 'react';
import '../header/Header.scss';

import Logo from '../../assets/visio-logo.svg';
import ProfileIcon from '../../assets/profile-icon.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db, logout } from 'src/firebase';
function Header() {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
    fetchUserName();
  }, [user, loading]);
  return (
    <div>
      <section className="header">
        <div className="header-logo">
          <a>
            {' '}
            <img src={Logo} />
          </a>
        </div>
        <div className="header-profile">
          <div className="header-profile-bell"></div>
          <div className="header-profile-person">
            <div className="header-profile-person-img">
              <img src={ProfileIcon} />
            </div>
            <div className="header-profile-person-info">
              <h6>{name}</h6>
              <p>{user?.email}</p>
              <button className="dashboard__btn" onClick={logout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Header;
