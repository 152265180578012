/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Licences from 'src/Licences';
import { licences } from 'src/licence-sync-service';
import './TableData.scss';
import LicenceItem from 'src/LicenceItem';
import SearchIcon from '../../assets/search.svg';
import MoreActiveIcon from 'src/assets/more-active-icon.svg';
import MoreOptions from '../moreOptions/MoreOptions';
import { ReactComponent as ActiveIcon } from 'src/assets/status-active.svg';
import { ReactComponent as InactiveIcon } from 'src/assets/status-inactive.svg';
import { ReactComponent as RevokeIcon } from 'src/assets/status-revoke.svg';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import moment from 'moment';
import CloseIcon from '../../assets/close.png';
import { Alert, Snackbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { async } from '@firebase/util';

const active = { borderBottom: '2px solid #237fc2', marginBottom: '-2px' };
const inactive = {};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 20px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

function Table() {
  const [loading, setLoading] = useState(true);
  const [licence, setLicence] = useState<Licences | Record<string, never>>({});
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(1);
  const [licenceKey, setLicenceKey] = useState<any>();
  const [machineUUID, setMachineUUID] = useState('');
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleMachineUUID = (event: any) => setMachineUUID(event.target.value);
  const handleEmail = (event: any) => setEmail(event.target.value);
  const [key, setKey] = useState('');
  const [email, setEmail] = useState('');
  const [snackActive, setSnackActive] = useState(false);
  const [snackRevoke, setSnackRevoke] = useState(false);
  const [snackInfo, setSnackInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [numberOfLicences, setnumberOfLicences] = useState(1);
  const [numberOfTerminals, setNumberOfTerminals] = useState(1);
  const [testLicence, setTestLicence] = useState(false);
  const [pending, setPending] = React.useState(true);
  const handleClick = (divNum: React.SetStateAction<number>) => () => {
    setSelected(divNum);
  };
  const { content } = licence;
  const more = { MoreActiveIcon };
  const handleClickSnack = () => {
    setOpen(true);
  };

  useEffect(() => {
    getLicences();
  }, []);
  const getLicences = async () => {
    setLoading(true);
    try {
      licences.getLicence().then((response) => {
        setLicence(response);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleNumberOfLicences = (event: any) => {
    const min = 0;
    const max = 30;
    const numberOfLicences = Math.max(
      min,
      Math.min(max, Number(event.target.value))
    );
    setnumberOfLicences(numberOfLicences);
  };

  const handleNumberOfTerminals = (event: any) => {
    const min = 0;
    const max = 99;
    const numberOfTerminals = Math.max(
      min,
      Math.min(max, Number(event.target.value))
    );
    setNumberOfTerminals(numberOfTerminals);
  };

  const handleTestLicence = (event: any) => {
    setTestLicence(event.target.checked);
  };

  const postData = async () => {
    try {
      const response = await licences.postNewLicence(
        numberOfLicences,
        numberOfTerminals,
        testLicence
      );
      setnumberOfLicences(response);
      setNumberOfTerminals(response);
      setTestLicence(response);
      setSnackInfo(true);
      handleClickSnack();
    } catch (error) {
      console.error(error);
    }
  };

  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(!modal);
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  const activateLicence = async (
    licenceKey: string,
    machineUUID: string,
    email: string
  ) => {
    try {
      const response = await licences.postActiveLicence(
        licenceKey,
        machineUUID,
        email
      );
      setLicenceKey(response);
      setMachineUUID(response);
      setEmail(response);
      getLicences();
      setSnackRevoke(false);
      setSnackActive(true);
      handleClickSnack();
      handleClick(1);
      setSelected(1);
    } catch (error) {
      console.error(error);
    }
  };

  const revokeLicence = async (key: string) => {
    try {
      const response = await licences.postRevokeLicence(key);
      setKey(response);
      getLicences();
      setSnackRevoke(true);
      handleClickSnack();
      handleClick(1);
      setSelected(1);
    } catch (error) {
      console.error(error);
    }
  };
  const updateNumberOfTerminal = async (
    licenceKey: string,
    numberOfTerminals: string
  ) => {
    try {
      const response = await licences.updateTerminal(
        licenceKey,
        numberOfTerminals
      );
      setLicenceKey(response);
      setNumberOfTerminals(response);
      getLicences();
      handleClick(1);
      setSelected(1);
    } catch (error) {
      console.error(error);
    }
  };
  const getFilteredData = async (value: any) => {
    try {
      const response = await licences.handleFilter(value);
      setLicence(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getTestData = async () => {
    try {
      const response = await licences.handleTestFilter();
      setLicence(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const renderSnackActivate = () => {
    return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => {
            {
              handleClose();
              setSnackActive(false);
            }
          }}>
          <Alert
            variant="filled"
            icon={false}
            onClose={() => {
              {
                handleClose();
                setSnackActive(false);
              }
            }}
            severity="success"
            sx={{ width: '100%' }}>
            Licence Activated!
          </Alert>
        </Snackbar>
      </Stack>
    );
  };

  const renderSnackRevoke = () => {
    return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => {
            {
              handleClose();
              setSnackRevoke(false);
            }
          }}>
          <Alert
            variant="filled"
            icon={false}
            onClose={() => {
              {
                handleClose();
                setSnackRevoke(false);
              }
            }}
            severity="error"
            sx={{ width: '100%' }}>
            Licence Revoked
          </Alert>
        </Snackbar>
      </Stack>
    );
  };
  const renderSnackInfo = () => {
    return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => {
            {
              handleClose();
              setSnackInfo(false);
            }
          }}>
          <Alert
            variant="filled"
            icon={false}
            onClose={() => {
              {
                handleClose();
                setSnackInfo(false);
              }
            }}
            severity="info"
            sx={{ width: '100%' }}>
            New Licence Created
          </Alert>
        </Snackbar>
      </Stack>
    );
  };

  const columns = [
    {
      name: 'Licence Key',
      selector: (row: { licenseKey: any }) => row.licenseKey,
      grow: 4
    },
    {
      name: 'Email',
      selector: (row: { email: any }) => row.email,
      grow: 2
    },
    {
      name: 'Machine ID',
      selector: (row: { machineUUID: any }) => row.machineUUID,
      grow: 3
    },
    {
      name: 'Terminals',
      selector: (row: { numberOfTerminals: any }) => row.numberOfTerminals,
      grow: 1
    },
    {
      name: 'Date Created',
      selector: (row: { createdAt: moment.MomentInput }) =>
        row.createdAt ? moment(row.createdAt).format('DD-MM-YYYY') : ''
    },
    {
      name: 'Date Activated',
      selector: (row: { activatedAt: any }) =>
        row.activatedAt ? moment(row.activatedAt).format('DD-MM-YYYY') : ''
    },
    {
      name: 'Date Revoked',
      selector: (row: { revokedAt: moment.MomentInput }) =>
        row.revokedAt ? moment(row.revokedAt).format('DD-MM-YYYY') : ''
    },
    {
      name: 'Expiration Date',
      selector: (row: { expiresAt: moment.MomentInput }) =>
        row.expiresAt ? moment(row.expiresAt).format('DD-MM-YYYY') : ''
    },
    {
      name: 'Status',
      cell: (row: { status: any }) =>
        row.status === 'ACTIVE' ? (
          <ActiveIcon />
        ) : <InactiveIcon /> && row.status === 'REVOKE' ? (
          <RevokeIcon />
        ) : (
          <InactiveIcon />
        ),
      width: '150px',
      center: true,
      compact: true
    },
    {
      name: 'Test',
      cell: (row: { testLicense: any }) =>
        row.testLicense == true ? (
          <ActiveIcon />
        ) : <InactiveIcon /> && row.testLicense == false ? (
          <RevokeIcon />
        ) : (
          <InactiveIcon />
        ),
      width: '150px',
      center: true,
      compact: true
    },
    {
      cell: (props: any) => (
        <MoreOptions
          cell={props}
          activateLicence={activateLicence}
          revokeLicence={revokeLicence}
          updateNumberOfTerminal={updateNumberOfTerminal}
        />
      )
    }
  ];
  const keys = ['licenseKey', 'email', 'machineUUID'];

  const CustomLoader = () => (
    <div
      style={{
        padding: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <Spinner />
      <div>Loading....</div>
    </div>
  );

  const search = (data: LicenceItem[]) => {
    return (licence.content || []).filter((item) =>
      keys.some((key) => item[key]?.toLowerCase().includes(query))
    );
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="flex">
        <h1>Licence Overview</h1>
        <button onClick={toggleModal} className="button_new">
          New Licence +
        </button>
        {modal && (
          <div className="modal">
            <div className="overlay"></div>
            <div className="modal-content">
              <h3>Enter number of licences</h3>
              <input
                value={numberOfLicences}
                required
                min="1"
                max="30"
                onChange={handleNumberOfLicences}
                type=""
              />
              <h3>Enter number of terminals per licence</h3>
              <input
                value={numberOfTerminals}
                required
                min="1"
                max="99"
                onChange={handleNumberOfTerminals}
                type=""
              />
              <div className="test_checkbox">
                <label>Test Licence</label>
                <input type="checkbox" onChange={handleTestLicence} />
              </div>
              <button
                className="button_new"
                onClick={async () => {
                  closeModal();
                  await postData();
                  const response = await licences.getLicence();
                  setLicence(response);
                }}
                disabled={!numberOfLicences && !numberOfTerminals}>
                Submit
              </button>
              <button className="close-modal" onClick={closeModal}>
                <img src={CloseIcon} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="licence_table">
          {
            <>
              <div className="filters">
                <div
                  style={selected == 1 ? active : inactive}
                  onClick={handleClick(1)}>
                  <button
                    style={selected == 1 ? active : inactive}
                    onClick={() => {
                      handleClick(1);
                      licences
                        .getLicence()
                        .then((response) => setLicence(response));
                    }}
                    className="filter__button">
                    All
                  </button>
                </div>
                <div
                  style={selected == 2 ? active : inactive}
                  onClick={handleClick(2)}>
                  <button
                    type="button"
                    style={selected == 2 ? active : inactive}
                    onClick={() => {
                      handleClick(2);
                      getFilteredData('NEW');
                    }}
                    className="filter__button">
                    New
                  </button>
                </div>
                <div
                  style={selected == 3 ? active : inactive}
                  onClick={handleClick(3)}>
                  <button
                    type="button"
                    style={selected == 3 ? active : inactive}
                    onClick={() => {
                      handleClick(3);
                      getFilteredData('ACTIVE');
                    }}
                    className="filter__button">
                    Active
                  </button>
                </div>
                <div
                  style={selected == 4 ? active : inactive}
                  onClick={handleClick(4)}>
                  <button
                    type="button"
                    style={selected == 4 ? active : inactive}
                    onClick={() => {
                      handleClick(4);
                      getFilteredData('REVOKE');
                    }}
                    className="filter__button">
                    Revoked
                  </button>
                </div>
                <div
                  style={selected == 5 ? active : inactive}
                  onClick={handleClick(5)}>
                  <button
                    style={selected == 5 ? active : inactive}
                    onClick={() => {
                      handleClick(5);
                      getFilteredData('EXPIRE');
                    }}
                    className="filter__button">
                    Expired
                  </button>
                </div>

                <div
                  style={selected == 6 ? active : inactive}
                  onClick={handleClick(6)}>
                  <button
                    style={selected == 6 ? active : inactive}
                    onClick={() => {
                      handleClick(6);
                      getTestData();
                    }}
                    className="filter__button">
                    Test Licence
                  </button>
                </div>
              </div>
              <div className="search-field">
                <input
                  type="text"
                  placeholder="Search by UUID, Email, MAC Address"
                  className="search"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <img src={SearchIcon} alt="" />{' '}
              </div>
              {loading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {CustomLoader()}
                </div>
              )}{' '}
              {!loading && (
                <DataTable
                  columns={columns}
                  data={search(licence.content)}
                  progressPending={pending}
                  pagination
                />
              )}
            </>
          }
        </div>
      </div>
      {snackActive && renderSnackActivate()}
      {snackRevoke && renderSnackRevoke()}
      {snackInfo && renderSnackInfo()}
    </>
  );
}

export default Table;
